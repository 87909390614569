import Helmet from 'react-helmet';
import React from 'react';
import { siteTitle, siteDescription } from '../site-config';
import Link from 'gatsby-link';
import Layout from '../layouts/index';

export default function Index() {
  return (
    <Layout>
      <Helmet title={siteTitle} meta={[{ name: 'description', content: siteDescription }]} />
      <div className="text-2xl text-blue-500">Hello world!!!</div>
      <Link to="/about">About</Link>
    </Layout>
  );
}
